@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Poppins", "Segoe UI", -apple-system, BlinkMacSystemFont,
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Poppins", "Segoe UI", source-code-pro, Monaco, Consolas,
    "Courier New", monospace;
}

.card {
  @apply flex flex-col items-center justify-around hover:bg-gray-300 bg-gray-200 rounded-3xl font-bold py-40  text-center;
}

.card-blue {
  @apply bg-blue-500 hover:bg-blue-700 text-white;
}

.blue-pulse {
  @apply animate-pulse hover:text-blue-500;
}

.ring-color {
  --tw-ring-color: rgb(255, 120, 120, var(--tw-ring-opacity));
}

.efficiently-blue {
  color: #46bcff;
}

.bg-efficiently-blue {
  background-color: #46bcff;
}
